<template>
  <modal @hide="$emit('close')">
    <template #header>
      <div>
        <h3 v-if="!esNuevo">{{ titleEditar }}</h3>
        <h3 v-else>{{ titleCrear }}</h3>
      </div>
    </template>
    <template>
      <div class="form">
        <card-form :style="combinedStyles" title="Informacion básica">
          <template>
            <card-form-item title="Código">
              <template #description>
                <input-number
                  v-model="itemMod.codigo"
                  v-bind="form.codigo"
                  :errors="form.codigo.errors"
                  :has-validation="true"
                />
              </template>
            </card-form-item>
            <card-form-item title="Nombre">
              <template #description>
                <input-box
                  v-model="itemMod.nombre"
                  v-bind="form.nombre"
                  :errors="form.nombre.errors"
                  :has-validation="true"
                />
              </template>
            </card-form-item>
            <card-form-item title="Proveedor">
              <template #description>
                <div>
                  <select-box
                    key="proveedor"
                    v-model="itemMod.id_proveedor"
                    :filter="true"
                    :options="opcionesProveedor"
                    v-bind="form.nombre_proveedor"
                    :errors="form.nombre_proveedor.errors"
                    :has-validation="true"
                  />
                </div>
              </template>
            </card-form-item>
            <card-form-item title="Control genéricos">
              <template #description>
                <div>
                  <check-box v-model="itemMod.genericos" :type="checkboxType" />
                </div>
              </template>
            </card-form-item>
            <card-form-item
              v-if="!loading && itemMod.genericos"
              title="Laboratorio genéricos 1"
            >
              <template #description>
                <div>
                  <select-box
                    key="laboratorio1"
                    v-model="itemMod.laboratorio1"
                    :filter="true"
                    :options="opcionesLaboratorio"
                    v-bind="form.laboratorio1"
                  />
                </div>
              </template>
            </card-form-item>
            <!-- <card-form-item
              v-if="!loading && itemMod.genericos"
              title="Volumen pactado laboratorio 1"
            >
              <template #description>
                <input-number
                  v-model="itemMod.volumen_pactado_genericos1"
                  v-bind="form.volumen_pactado_genericos1"
                />
              </template>
            </card-form-item> -->
            <card-form-item
              v-if="!loading && itemMod.genericos"
              title="Laboratorio genéricos 2"
            >
              <template #description>
                <div>
                  <select-box
                    key="laboratorio2"
                    v-model="itemMod.laboratorio2"
                    :filter="true"
                    :options="opcionesLaboratorio"
                    v-bind="form.laboratorio2"
                  />
                </div>
              </template>
            </card-form-item>
            <!-- <card-form-item
              v-if="!loading && itemMod.genericos"
              title="Volumen pactado laboratorio 2"
            >
              <template #description>
                <input-number
                  v-model="itemMod.volumen_pactado_genericos2"
                  v-bind="form.volumen_pactado_genericos2"
                />
              </template>
            </card-form-item> -->
            <card-form-item title="Usuario fedicom">
              <template #description>
                <input-box
                  v-model="itemMod.user_fedicom"
                  v-bind="form.user_fedicom"
                  :errors="form.user_fedicom.errors"
                  :has-validation="true"
                />
              </template>
            </card-form-item>
            <card-form-item title="Contraseña fedicom">
              <template #description>
                <input-box
                  v-model="itemMod.pass_fedicom"
                  v-bind="form.pass_fedicom"
                  :errors="form.pass_fedicom.errors"
                  :has-validation="true"
                />
              </template>
            </card-form-item>
            <card-form-item title="Volumen pactado">
              <template #description>
                <input-box
                  v-model="itemMod.volumen_pactado"
                  v-bind="form.volumen_pactado"
                  :errors="form.volumen_pactado.errors"
                  :has-validation="true"
                />
              </template>
            </card-form-item>
            <card-form-item title="Descuento inicial">
              <template #description>
                <input-box
                  v-model="itemMod.dto_inicial"
                  v-bind="form.dto_inicial"
                  type="number"
                  :errors="form.dto_inicial.errors"
                  :has-validation="true"
                />
              </template>
            </card-form-item>
          </template>
        </card-form>
      </div>
    </template>
    <template #footer>
      <div class="d-flex" :style="colorStyles">
        <div class="p-3">
          <b-button variant="outline-primary" @click="$emit('close')">{{
            $t('button.cancel')
          }}</b-button>
        </div>
        <div class="p-3">
          <b-button variant="primary" @click="guardar">{{
            $t('button.save')
          }}</b-button>
        </div>
      </div>
    </template>
  </modal>
</template>
<script>
import farmacias from '@/api/farmacias.api';
import proveedores from '@/api/proveedores.api';
import laboratorios from '@/api/laboratorios.api';
import colors from '@/mixins/colors.js';

export default {
  name: 'ModalFarmacias',
  components: {},
  extends: farmacias,
  mixins: [colors, proveedores, laboratorios],
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    esNuevo: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      titleEditar: 'Editar',
      titleCrear: 'Crear',
      itemMod: {},
      opcionesProveedor: [],
      opcionesLaboratorio: [],
      form: {
        codigo: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        nombre: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        nombre_proveedor: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        laboratorio1: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        volumen_pactado_genericos1: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        laboratorio2: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        volumen_pactado_genericos2: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        user_fedicom: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        pass_fedicom: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        volumen_pactado: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        dto_inicial: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
      },
      esEstandar: true,
    };
  },
  mounted() {
    this.loadFiltros();
    this.loadLaboratorios();
  },
  methods: {
    loadDatosFarmacia() {
      if (this.id) {
        let createPromise = new Promise((resolve) => {
          this.findFarmacia(resolve, this.id);
        });
        createPromise.then((data) => {
          if (data) {
            this.itemMod = data;
          }
        });
      }
    },
    actualizarCheck: function (data) {
      console.log(data);
    },
    handleSelect: function (data) {
      console.log(data);
    },

    guardar() {
      this.cleanForm();
      if (!this.validateForm()) {
        return;
      }
      // this.itemMod = {
      //   ...this.itemMod,
      // };

      this.itemMod.dto_inicial = parseFloat(this.itemMod.dto_inicial);

      this.itemMod.laboratorios = [];
      if (this.itemMod.laboratorio1) {
        this.itemMod.laboratorios.push({
          id_laboratorio: this.itemMod.laboratorio1,
          volumen_pactado: this.itemMod.volumen_pactado_genericos1,
        });
      }
      if (this.itemMod.laboratorio2) {
        this.itemMod.laboratorios.push({
          id_laboratorio: this.itemMod.laboratorio2,
          volumen_pactado: this.itemMod.volumen_pactado_genericos2,
        });
      }
      if (this.esNuevo) {
        let createPromise = new Promise((resolve) => {
          this.createFarmacia(resolve, this.itemMod);
        });
        createPromise.then((data) => {
          this.$emit('save');
          this.$emit('close');
        });
      } else {
        let updatePromise = new Promise((resolve) => {
          this.updateFarmacia(resolve, this.itemMod, this.itemMod.id_farmacia);
        });
        updatePromise.then((data) => {
          this.$emit('save');
          this.$emit('close');
        });
      }
    },
    loadFiltros: function () {
      let proveedoresPromise = new Promise((resolve) => {
        this.getProveedores(resolve);
      });
      Promise.all([proveedoresPromise]).then(([proveedoresData]) => {
        this.opcionesProveedor = proveedoresData.map((item) => {
          item.text = item.nombre;
          item.value = item.id_proveedor;
          return item;
        });
      });
    },
    loadLaboratorios: function () {
      this.loading = true;
      let laboratoriosPromise = new Promise((resolve) => {
        this.getLaboratorios(resolve);
      });
      laboratoriosPromise.then((data) => {
        this.opcionesLaboratorio = [];
        data.forEach((element) => {
          this.opcionesLaboratorio.push({
            value: element.id_laboratorio + '',
            text: element.nombre,
          });
        });
        this.loading = false;
        this.loadDatosFarmacia();
      });
    },

    validateForm() {
      let validado = true;
      if (!this.itemMod.descripcion === '') {
        this.form.descripcion.hasError = true;
        this.form.descripcion.hasValidation = true;
        this.form.descripcion.errors.push({
          show: true,
          message: this.$t('error.obligatorio.default'),
        });
        validado = false;
      }

      if (!this.itemMod.codigo) {
        this.form.codigo.hasError = true;
        this.form.codigo.errors.push({
          show: true,
          message: 'Introduce el código',
        });
        validado = false;
      } else {
        this.form.codigo.hasError = false;
        this.form.codigo.errors = [];
      }

      if (!this.itemMod.nombre) {
        this.form.nombre.hasError = true;
        this.form.nombre.errors.push({
          show: true,
          message: 'Introduce un nombre',
        });
        validado = false;
      } else {
        this.form.nombre.hasError = false;
        this.form.nombre.errors = [];
      }

      if (!this.itemMod.id_proveedor) {
        this.form.nombre_proveedor.hasError = true;
        this.form.nombre_proveedor.errors.push({
          show: true,
          message: 'Selecciona un proveedor',
        });
        validado = false;
      } else {
        this.form.nombre_proveedor.hasError = false;
        this.form.nombre_proveedor.errors = [];
      }

      if (!this.itemMod.user_fedicom) {
        this.form.user_fedicom.hasError = true;
        this.form.user_fedicom.errors.push({
          show: true,
          message: 'Introduce el usuario',
        });
        validado = false;
      } else {
        this.form.user_fedicom.hasError = false;
        this.form.user_fedicom.errors = [];
      }

      if (!this.itemMod.pass_fedicom) {
        this.form.pass_fedicom.hasError = true;
        this.form.pass_fedicom.errors.push({
          show: true,
          message: 'Introduce la contraseña',
        });
        validado = false;
      } else {
        this.form.pass_fedicom.hasError = false;
        this.form.pass_fedicom.errors = [];
      }

      if (!this.itemMod.volumen_pactado) {
        this.form.volumen_pactado.hasError = true;
        this.form.volumen_pactado.errors.push({
          show: true,
          message: 'Introduce el volumen pactado',
        });
        validado = false;
      } else {
        this.form.volumen_pactado.hasError = false;
        this.form.volumen_pactado.errors = [];
      }

      if (!this.itemMod.dto_inicial) {
        this.form.dto_inicial.hasError = true;
        this.form.dto_inicial.errors.push({
          show: true,
          message: 'Introduce el descuento incicial',
        });
        validado = false;
      } else {
        this.form.dto_inicial.hasError = false;
        this.form.dto_inicial.errors = [];
      }

      return validado;
    },
    cleanForm: function () {
      this.form = {
        descripcion: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        codigo: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        nombre: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        nombre_proveedor: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        laboratorio1: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        volumen_pactado_genericos1: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        laboratorio2: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        volumen_pactado_genericos2: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        user_fedicom: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        pass_fedicom: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        volumen_pactado: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        dto_inicial: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
      };
    },
  },
};
</script>

<style lang="scss" scope>
.form {
  overflow: visible;
}
</style>
