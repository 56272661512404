<template>
  <div id="control">
    <b-row>
      <b-col>
        <h4>{{ title }}</h4>
      </b-col>
    </b-row>
    <DataTableNafScroll
      :items="items"
      :fields="fields"
      :is-fetching="isFetching"
      :per-page="5"
      :grid-cols="gridCols"
      :is-compact="isCompactTable"
      compact-fields="nombre"
    >
      <template #cell(farmacia)="{ item }">
        <span>{{ item.farmacia }}</span>
      </template>
      <template #cell(ejercicio)="{ item }">
        <span>{{ item.ejercicio }}</span>
      </template>
      <template #cell(mes)="{ item }">
        <span>{{ item.mes }}</span>
      </template>
      <template #cell(comprasTotal)="{ item }">
        <span> {{ $n(parseFloat(item.comprasTotal), 'currency') }} </span>
      </template>
      <template #cell(comprasEspecialidad)="{ item }">
        <span>
          {{ $n(parseFloat(item.comprasEspecialidad), 'currency') }}
        </span>
      </template>
      <template #cell(comprasParafarmacia)="{ item }">
        <span>
          {{ $n(parseFloat(item.comprasParafarmacia), 'currency') }}
        </span>
      </template>
      <template #cell(dtoTotal)="{ item }">
        <span>
          {{ $n(parseFloat(item.dtoTotal), 'currency') }}
        </span>
      </template>
      <template #cell(beneficioTotal)="{ item }">
        <span>
          {{ $n(parseFloat(item.beneficioTotal), 'currency') }}
        </span>
      </template>
      <template #cell(beneficioEspecialidad)="{ item }">
        <span>
          {{ $n(parseFloat(item.beneficioEspecialidad), 'currency') }}
        </span>
      </template>
      <template #cell(beneficioParafarmacia)="{ item }">
        <span>
          {{ $n(parseFloat(item.beneficioParafarmacia), 'currency') }}
        </span>
      </template>
      <template #cell(volumen_pactado)="{ item }">
        <span> {{ $n(item.volumen_pactado, 'currency') }}</span>
      </template>
      <template #cell(cumplimiento)="{ item }">
        <span :style="{ color: getCumplimientoColor(item.cumplimiento) }">
          {{ $n(item.cumplimiento, 'percentDecimal') }}
        </span>
      </template>
      <template #cell(laboratoriogenericos1porcentaje)="{ item }">
        <span>
          {{ $n(item.laboratoriogenericos1porcentaje, 'percentDecimal') }}
        </span>
      </template>
      <template #cell(laboratoriogenericos2porcentaje)="{ item }">
        <span>
          {{ $n(item.laboratoriogenericos2porcentaje, 'percentDecimal') }}
        </span>
      </template>
      <template #cell(porcentajetotal)="{ item }">
        <span :style="{ color: getCumplimientoColor(item.porcentajetotal) }">
          {{ $n(item.porcentajetotal, 'percentDecimal') }}
        </span>
      </template>
    </DataTableNafScroll>
  </div>
</template>

<script>
import DataTableNafScroll from '../../commons/DataTableNafScroll.vue';

export default {
  name: 'TablaControlFarmacias',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: 'Título por defecto',
    },
    uniquekey: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'listado',
    },
    isFetching: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isCompactTable: false,
      fields: [],
      fieldsDato: [
        {
          key: 'farmacia',
          sortable: true,
          label: 'Farmacia',
          header: 'Farmacia',
          tooltip: '',
          width: '250px',
        },
        {
          key: 'ejercicio',
          sortable: true,
          label: 'Ejercicio',
          header: 'Ejercicio',
          tooltip: '',
        },
        {
          key: 'mes',
          sortable: true,
          label: 'Mes',
          header: 'Mes',
          tooltip: '',
        },
        {
          key: 'comprasTotal',
          sortable: true,
          label: ' Total compras',
          header: 'Total compras',
          tooltip: '',
        },
        // {
        //   key: 'comprasEspecialidad',
        //   sortable: true,
        //   label: 'Compras Especialidad',
        //   header: 'Compras Especialidad',
        //   tooltip: '',
        // },
        // {
        //   key: 'comprasParafarmacia',
        //   sortable: true,
        //   label: 'Compras Parafarmacia',
        //   header: 'Compras Parafarmacia',
        //   tooltip: '',
        // },

        {
          key: 'beneficioTotal',
          sortable: true,
          label: 'Beneficio Total',
          header: 'Beneficio Total',
          tooltip: '',
        },
        // {
        //   key: 'beneficioEspecialidad',
        //   sortable: true,
        //   label: 'Beneficio Especialidad',
        //   header: 'Beneficio Especialidad',
        //   tooltip: '',
        // },
        // {
        //   key: 'beneficioParafarmacia',
        //   sortable: true,
        //   label: 'Beneficio Parafarmacia',
        //   header: 'Beneficio Parafarmacia',
        //   tooltip: '',
        // },

        {
          key: 'volumen_pactado',
          sortable: true,
          label: 'Volumen Pactado',
          header: 'Volumen Pactado',
          tooltip: '',
        },
        {
          key: 'cumplimiento',
          sortable: true,
          label: 'Cumplimiento',
          header: 'Cumplimiento',
          tooltip: '',
        },
        {
          key: 'laboratoriogenericos1',
          sortable: true,
          label: 'Laboratorio genéricos 1',
          header: 'Laboratorio genéricos 1',
          tooltip: '',
        },
        {
          key: 'laboratoriogenericos1porcentaje',
          sortable: true,
          label: 'Porcentaje genéricos 1',
          header: 'Porcentaje genéricos 1',
          tooltip: '',
        },
        {
          key: 'laboratoriogenericos2',
          sortable: true,
          label: 'Laboratorio genéricos 2',
          header: 'Laboratorio genéricos 2',
          tooltip: '',
        },
        {
          key: 'laboratoriogenericos2porcentaje',
          sortable: true,
          label: 'Porcentaje genéricos 2',
          header: 'Porcentaje genéricos 2',
          tooltip: '',
        },
        {
          key: 'porcentajetotal',
          sortable: true,
          label: 'Cumplimiento compromiso genéricos',
          header: 'Cumplimiento compromiso genéricos',
          tooltip: '',
        },
      ],

      gridCols: 'fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    };
  },
  watch: {
    type: {
      immediate: true,
      handler: 'updateFields',
    },
  },
  mounted() {
    this.isCompactTable = window.innerWidth < 992;
    this.fields = this.fieldsDato.filter((field) => field.key !== 'mes');
    window.addEventListener('resize', this.handleUpdateScreenSize);
  },
  methods: {
    handleUpdateScreenSize: function () {
      this.isCompactTable = window.innerWidth < 992;
    },
    updateFields() {
      if (this.type === 'Mes') {
        this.fields = this.fieldsDato;
        this.gridCols = '2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr';
      } else {
        this.fields = this.fieldsDato.filter((field) => field.key !== 'mes');
        this.gridCols = '2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr';
      }
    },
    getCumplimientoColor(percent) {
      if (percent >= 0.8) {
        return '#75B84E'; // Verde
      } else if (percent < 0.8 && percent >= 0.7) {
        return '#E3B428'; // Amarillo
      } else {
        return '#E72D51'; // Rojo
      }
    },
  },
};
</script>

<style scoped lang="scss">
#control {
  h4 {
    span {
      color: var(--fc-brand-01);
      text-transform: lowercase;
    }
  }
  @media screen and (max-width: 992px) {
    ::v-deep .compact-fields {
      display: flex;
      flex-wrap: wrap;
    }
    ::v-deep .grid {
      grid-template-columns: none !important;
      justify-content: start;
    }
  }
}
</style>
