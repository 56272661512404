<template>
  <BaseView :title="title">
    <template #content>
      <div>
        <div class="cards-title text-uppercase">beneficio</div>
        <div class="cards">
          <div>
            <compras-card
              :on-click="
                () =>
                  redirect(
                    'Detalle beneficio acumulado anual',
                    'acumulado',
                    false
                  )
              "
              :title="
                'Acumulado anual ' + datosCompras.beneficioAnual.ejercicio
              "
              :euros="datosCompras.beneficioAnual.beneficioTotal"
              :percent="datosCompras.beneficioAnual.cumplimiento"
              :data-info="datosCompras.fechaActulizacion"
              :percent-espe="datosCompras.beneficioAnual.percentEspe"
              :percent-para="datosCompras.beneficioAnual.percentPara"
              :percent-publi="datosCompras.beneficioAnual.percentPubli"
            />
          </div>
          <div>
            <compras-card
              :on-click="
                () =>
                  redirect(
                    'Detalle beneficio ' +
                      datosCompras.beneficioMesCerrado.title,
                    'mes',
                    false
                  )
              "
              :title="datosCompras.beneficioMesCerrado.title"
              :euros="datosCompras.beneficioMesCerrado.beneficioTotal"
              :percent="datosCompras.beneficioMesCerrado.cumplimiento"
              :data-info="datosCompras.fechaActulizacion"
              :percent-espe="datosCompras.beneficioMesCerrado.percentEspe"
              :percent-para="datosCompras.beneficioMesCerrado.percentPara"
              :percent-publi="datosCompras.beneficioMesCerrado.percentPubli"
            />
          </div>
        </div>
      </div>
      <div>
        <div class="cards-title text-uppercase">genéricos</div>
        <div class="cards">
          <div
            v-if="
              datosCompras.genericosAnual &&
              datosCompras.genericosAnual.length > 0
            "
          >
            <compras-card-generic
              :on-click="
                () =>
                  redirect('Detalle genérico acumulado anual', 'anual', true)
              "
              :title="
                'Acumulado anual ' + datosCompras.beneficioAnual.ejercicio
              "
              :labs="datosCompras.genericosAnual"
              :data-info="datosCompras.fechaActulizacion"
              :percentgenericos="percentgenericosanual"
            />
          </div>

          <div
            v-if="
              datosCompras.genericosMes && datosCompras.genericosMes.length > 0
            "
          >
            <compras-card-generic
              :on-click="
                () => redirect('Detalle genérico acumulado mes', 'mes', true)
              "
              :title="datosCompras.beneficioMesCerrado.title"
              :labs="datosCompras.genericosMes"
              :data-info="datosCompras.fechaActulizacion"
              :percentgenericos="percentgenericosmes"
            />
          </div>
        </div>
      </div>
    </template>
  </BaseView>
</template>

<script>
import ComprasGraph from '@/components/compras/ComprasGraph.vue';
import ComprasCard from '@/components/compras/ComprasCard.vue';
import ComprasCardGeneric from '@/components/compras/ComprasCardGeneric.vue';

import homeApi from '@/api/home.api';
import { mapGetters } from 'vuex';

export default {
  name: 'Compras',
  components: {
    ComprasCard,
    ComprasGraph,
    ComprasCardGeneric,
  },
  extends: homeApi,
  data() {
    return {
      datosCompras: [],
      laboratorios: [],
      title: 'Compras',
      periodo: '',
      genericos: false,
    };
  },

  watch: {},
  async mounted() {
    this.loadItems();
  },
  methods: {
    redirect(tableName, periodo, genericos) {
      sessionStorage.setItem(
        'redirectData',
        JSON.stringify({ tableName, periodo, genericos })
      );

      this.$router.push({
        path: '/compras/resumen',
      });
    },
    loadItems() {
      let datosComprasPromise = new Promise((resolve) => {
        this.getDatosCompras(resolve, this.request);
      });
      datosComprasPromise.then((data) => {
        this.datosCompras = [];
        this.datosCompras = data;
        this.percentgenericosmes = 0.0;
        this.percentgenericosanual = 0.0;
        data.genericosMes.forEach((item) => {
          this.percentgenericosmes += item.porcentaje;
        });

        data.genericosAnual.forEach((item) => {
          if (!this.laboratorios.includes(item.id_laboratorio)) {
            this.laboratorios.push(item.id_laboratorio);
          }
          this.percentgenericosanual += item.porcentaje;
        });
        this.laboratorios.forEach((item) => {
          this.descargarFotoLab(item);
        });
      });
    },
    descargarFotoLab(idlaboratorio) {
      let fotolabpromise = new Promise((resolve) => {
        this.getFotoLab(resolve, idlaboratorio);
      });
      fotolabpromise.then((datafoto) => {
        this.datosCompras.genericosAnual.forEach((item, index) => {
          if (item.id_laboratorio == idlaboratorio) {
            this.datosCompras.genericosAnual[index].urlFoto = datafoto.logo_url;
            this.datosCompras.genericosAnual = [
              ...this.datosCompras.genericosAnual,
            ];
          }
        });
        this.datosCompras.genericosMes.forEach((item, index) => {
          if (item.id_laboratorio == idlaboratorio) {
            this.datosCompras.genericosMes[index].urlFoto = datafoto.logo_url;
            this.datosCompras.genericosMes = [
              ...this.datosCompras.genericosMes,
            ];
          }
        });
      });
    },
  },
};
</script>

<style>
.layout {
  padding: 53px 20px;
}
.compras-title {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 103px;
}

.cards {
  display: flex;
  gap: 0 78px;
  margin-bottom: 44px;
}
.cards-title {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 27px;
}
</style>
